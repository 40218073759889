<template>
  <div class="screen-breadcrumb">
    <div class="screen-breadcrumb__item" v-for="(breadcrumb, idx) in breadcrumbs" :key="`breadcrumb-${idx}`">
      <v-icon v-if="idx > 0" class="screen-breadcrumb__item__separator" small color="#97979b">{{
        icons.mdiChevronRight
      }}</v-icon>
      <component
        :is="idx !== breadcrumbs.length - 1 ? 'router-link' : 'div'"
        :to="idx !== breadcrumbs.length - 1 ? breadcrumb.route : null"
        class="screen-breadcrumb__item__label"
        :class="{ 'screen-breadcrumb__item__label--with-link': idx !== breadcrumbs.length - 1 }"
      >
        {{ breadcrumb.label }}
      </component>
    </div>
  </div>
</template>

<script>
import { mdiChevronRight } from '@mdi/js'

export default {
  name: 'ScreenBreadcrumb',
  props: {
    breadcrumbs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    icons: {
      mdiChevronRight,
    },
  }),
}
</script>

<style lang="scss" scoped>
.screen-breadcrumb {
  display: flex;
  align-items: center;

  &__home {
    margin-right: -2px;
    margin-left: -2px;
    text-decoration: none;
    color: var(--text-color-alt);
    font-size: $font-size-xl;

    &:hover {
      color: $generic-color-gtr;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:last-child {
      overflow: hidden;
    }

    &__separator {
      margin: 0 2px;
    }

    &__label {
      overflow: hidden;
      text-decoration: none;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--text-color-alt);
      font-size: $font-size-sm;
      font-weight: 500;

      &--with-link {
        &:hover {
          color: $generic-color-gtr;
        }
      }
    }
  }
}
</style>
