<template>
  <div
    class="screen-card"
    :class="[
      `screen-card--${ratio}`,
      { 'screen-card--no-overflow': overflowHidden, 'screen-card--full-height': fullHeight },
    ]"
  >
    <header class="screen-card__header" v-if="title !== ''">
      <section class="screen-card__header__section screen-card__header__section--align-start">
        <skeleton-line height="20px" v-if="isLoading" />
        <ui-logo
          v-else-if="logo"
          class="screen-card__header__section__logo"
          :logo="logo"
          width="90px"
          height="auto"
        ></ui-logo>
        <div class="screen-card__header__section__title" v-if="!isLoading">
          <slot name="title">
            {{ title }}
            <ui-helper class="screen-card__header__section__title__helper" :helper="helper"></ui-helper>
          </slot>
        </div>
        <skeleton-line height="15px" margin-top="3px" v-if="isLoading && subTitle" />
        <div class="screen-card__header__section__sub-title" v-else-if="!isLoading && subTitle">
          {{ subTitle }}
        </div>
      </section>
      <section class="screen-card__header__section screen-card__header__section--align-end">
        <slot name="header-right"></slot>
      </section>
    </header>
    <div
      class="screen-card__body"
      :class="{
        'screen-card__body--no-title': title === '' && noPadding === false,
        'screen-card__body--no-padding': noPadding === true,
        'screen-card__body--align-center': align === 'center',
        'screen-card__body--relative': isRelative,
      }"
    >
      <div
        class="screen-card__body__grid"
        :class="{
          'screen-card__body__grid--body-full-height': bodyFullHeight,
          'screen-card__body__grid--no-margin': noMargin,
        }"
        v-if="display === 'grid'"
      >
        <slot name="body"></slot>
      </div>
      <div
        class="screen-card__body__flex"
        :class="{ 'screen-card__body__flex--full-height': fullHeight }"
        v-else-if="display === 'flex'"
      >
        <slot name="body"></slot>
      </div>
      <template v-else>
        <slot name="body"></slot>
      </template>
    </div>
  </div>
</template>

<script>
import SkeletonLine from '@/components/Skeleton/Line.vue'
import UiLogo from '@/components/UI/Logo.vue'
import UiHelper from '@/components/UI/Helper.vue'

export default {
  name: 'ScreenCard',
  components: {
    SkeletonLine,
    UiLogo,
    UiHelper,
  },
  props: {
    helper: {
      type: String,
      required: false,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subTitle: {
      type: String,
      required: false,
      default: '',
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false,
    },
    noMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
    display: {
      type: String,
      required: false,
      default: 'column',
    },
    align: {
      type: String,
      required: false,
      default: 'top',
    },
    ratio: {
      type: String,
      required: false,
      default: '1-1',
    },
    overflowHidden: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    bodyFullHeight: {
      type: Boolean,
      required: false,
      default: false,
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    isRelative: {
      type: Boolean,
      required: false,
      default: false,
    },
    logo: {
      type: [String, Boolean],
      required: false,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.screen-card {
  display: flex;
  flex-direction: column;
  margin: 0 (-$gutter-mobile);
  border-bottom: 1px solid var(--border-color);
  background: var(--bg-color);

  @media (min-width: $screen-sm) {
    margin: $gutter-tablet / 2;
    border: 0;
    border-radius: $radius-default;
    box-shadow: 0 0 15px var(--box-shadow-color);
    // width: calc(100% - #{$gutter-tablet});
    width: calc(100% - #{$gutter-tablet});
  }

  @media (min-width: $screen-xl) {
    margin: $gutter-desktop / 2;
  }

  &--no-overflow {
    overflow: hidden;
  }

  &--full-height {
    @media (min-width: $screen-md) {
      height: 100%;
    }
  }

  &--1-2 {
    @media (min-width: $screen-md) {
      width: calc(100% / 2 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 2 - #{$gutter-desktop});
    }
  }

  &--1-3 {
    @media (min-width: $screen-md) {
      width: calc(100% / 3 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 3 - #{$gutter-desktop});
    }
  }

  &--1-4 {
    @media (min-width: $screen-md) {
      width: calc(100% / 4 - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% / 4 - #{$gutter-desktop});
    }
  }

  &--2-3 {
    @media (min-width: $screen-md) {
      width: calc(100% * (2 / 3) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (2 / 3) - #{$gutter-desktop});
    }
  }

  &--3-4 {
    @media (min-width: $screen-md) {
      width: calc(100% * (3 / 4) - #{$gutter-tablet});
    }

    @media (min-width: $screen-xl) {
      width: calc(100% * (3 / 4) - #{$gutter-desktop});
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: $gutter-mobile;

    @media (min-width: $screen-sm) {
      padding: $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding: $gutter-desktop;
    }

    &__section {
      display: flex;
      flex-direction: column;

      &--align-start {
        flex: 1;
        justify-content: flex-start;
      }

      &--align-end {
        justify-content: flex-end;
        margin-left: $gutter-mobile;
      }

      &__logo {
        align-self: center;
      }

      &__title {
        display: flex;
        flex-direction: row;
        font-size: $font-size-lg;
        font-weight: 600;

        &__helper {
          margin-left: 2px;
        }
      }

      &__sub-title {
        margin-top: 3px;
        text-align: center;
        color: $blue-bayoux;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    padding: 0 $gutter-mobile $gutter-mobile;
    height: 100%;

    @media (min-width: $screen-sm) {
      padding: 0 $gutter-tablet $gutter-tablet;
    }

    @media (min-width: $screen-xl) {
      padding: 0 $gutter-desktop $gutter-desktop;
    }

    &--no-padding {
      padding: 0;
    }

    &--align-center {
      justify-content: center;
    }

    &--no-title {
      padding-top: $gutter-mobile;

      @media (min-width: $screen-sm) {
        padding-top: $gutter-tablet;
      }

      @media (min-width: $screen-xl) {
        padding-top: $gutter-desktop;
      }
    }

    &--relative {
      position: relative;
    }

    &__grid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin: (-$gutter-mobile / 2);

      @media (min-width: $screen-sm) {
        margin: (-$gutter-tablet / 2);
      }

      @media (min-width: $screen-xl) {
        margin: (-$gutter-desktop / 2);
      }

      &--no-margin {
        margin: 0;
      }

      &--body-full-height {
        height: 100%;
      }
    }

    &__flex {
      display: flex;
      flex-direction: row;

      &--full-height {
        @media (min-width: $screen-md) {
          height: 100%;
        }
      }
    }
  }
}
</style>
