<template>
  <v-tooltip v-model="show" color="#449afd" v-if="hasHelper" top>
    <template v-slot:activator="{ on, attrs }">
      <div @click.stop="show = !show" class="helper__icon helper__icon--info backoffice-icons" v-bind="attrs" v-on="on">
        warning
      </div>
    </template>
    <div class="helper__content">
      {{ helper }}
    </div>
  </v-tooltip>
</template>

<script>
export default {
  name: 'UiHelper',
  props: {
    helper: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      show: false,
    }
  },
  computed: {
    hasHelper() {
      return this.helper && this.helper !== ''
    },
  },
}
</script>

<style lang="scss" scoped>
.helper {
  &__icon {
    margin-left: 3px;
    color: $orange-tree-poppy;
    font-size: $font-size-lg;

    &--info {
      cursor: pointer;
      color: $blue-royal;
    }
  }
  &__content {
    border: 1px solid $blue-royal;
    padding: 7px 10px;
    max-width: 220px;
    color: rgb(255, 255, 255);
    font-size: calc(#{$font-size-sm} + 0.7px);
    font-weight: 400;
    hyphens: auto;
  }
}
</style>
